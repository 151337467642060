<template>
  <v-container fluid class="content-wrapper" style="background: #f4f5fa">
    <div class="page-slideout">
      <div class="section">
        <div class="container">
          <!-- <v-container fluid> -->
          <div class="overflow-y-auto d-flex flex-column pa-3 mt-5">
            <div class="d-flex flex-row justify-space-between align-center mb-5">
              <h3 class="title-md">{{ $t("PropertyManagement.title") }}</h3>
              <v-btn
                v-if="checkRoleUserFunc() == 'provider' && !loadingUserData"
                depressed
                color="pink white--text"
                @click="addNewPropertyNewTab()"
              >
                <v-icon left>mdi-home-city-outline</v-icon>
                {{ $t("PropertyManagement.btn_add_property") }}
              </v-btn>
            </div>
            <v-data-table
              :headers="headers"
              :items="itemsProperty"
              :footer-props="{
                'items-per-page-options': [5],
              }"
              :items-per-page="5"
              class="elevation-1"
              @click:row="editProperty"
              fixed-top
              fixed-header
              style="max-height: 80vh; overflow-y: auto"
            >
              <template v-slot:item.name="{ item }">
                <div class="d-flex flex-row align-center justify-start py-5">
                  <img
                    :src="
                      item.img
                        ? item.img
                        : require('@/assets/img/thumb/placeholder-image.png')
                    "
                    alt
                    class="property-img"
                  />
                  <div class="d-flex flex-column align-start justify-center ml-3">
                    <span class="title-xsm mb-0">{{ item.name }}</span>
                    <span
                      class="title-xsm mb-0 tag-property-status text-center mt-2"
                      :style="
                        item.property_status == 'completed'
                          ? 'background-color: rgb(113, 245,74);'
                          : item.property_status == 'actived'
                          ? 'background-color: pink;'
                          : item.property_status == 'suspended'
                          ? 'background-color: red;'
                          : 'background-color: rgb(89, 49, 226);'
                      "
                      style="min-width: 7.5rem"
                    >
                      {{
                        item.property_status == "completed"
                          ? $t("PropertyManagement.complete")
                          : item.property_status == "actived"
                          ? $t("PropertyManagement.active")
                          : item.property_status == "suspended"
                          ? $t("PropertyManagement.suspend")
                          : $t("PropertyManagement.onboard")
                      }}
                    </span>
                  </div>
                </div>
              </template>
              <template v-slot:item.location="{ item }">
                <div class="d-flex flex-column align-start justify-start">
                  <div class="d-flex flex-row align-start justify-start">
                    <img
                      v-if="
                        item.contact.country == 'ไทย' ||
                        item.contact.country == 'Thailand'
                      "
                      :src="require('@/assets/thumb/flag-th.svg')"
                      alt
                      class="flag-img"
                    />
                    <div class="d-flex flex-column align-start justify-start ml-3">
                      <span
                        class="title-xsm mb-0"
                        v-if="
                          item.contact.address_line_1 &&
                          item.contact.address_line_2 &&
                          item.contact.subdistrict &&
                          item.contact.district &&
                          item.contact.province &&
                          item.contact.country
                        "
                      >
                        {{
                          `${item.contact.address_line_1} ${item.contact.address_line_2}, ${item.contact.subdistrict} ${item.contact.district}, ${item.contact.province}, ${item.contact.country}`
                        }}
                      </span>
                      <span
                        class="title-xsm mb-0"
                        v-if="
                          item.contact.address_line_1 &&
                          item.contact.subdistrict &&
                          item.contact.district &&
                          item.contact.province &&
                          item.contact.country
                        "
                      >
                        {{
                          `${item.contact.address_line_1}, ${item.contact.subdistrict} ${item.contact.district}, ${item.contact.province}, ${item.contact.country}`
                        }}
                      </span>
                      <span class="title-xsm mb-0" v-else>
                        {{ $t("SignupStep.nodata") }}
                      </span>
                    </div>
                  </div>
                  <div class="d-flex flex-row align-center justify-start">
                    <span class="title-xsm mb-0 mr-3">
                      {{ `${item.property_progress}%` }}
                    </span>
                    <v-progress-linear
                      :color="
                        item.property_progress == 100 ? 'rgb(113, 245,74)' : 'orange'
                      "
                      :value="item.property_progress"
                      style="width: 7rem"
                    ></v-progress-linear>
                  </div>
                </div>
              </template>
              <!-- <template v-slot:item.status="{ item }">
              <span class="title-xsm mb-0">{{
                item.status || "On boarding"
              }}</span>
              </template>-->
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  @click.stop.prevent="delProperty(item)"
                  v-if="
                    (!allPropertyIncomplete && item.property_progress < 100) ||
                    (allPropertyIncomplete && item.row_property !== 0)
                  "
                  >mdi-delete</v-icon
                >
              </template>
              <template v-slot:no-data>
                <v-btn color="primary" @click="initUserData()">Reset</v-btn>
              </template>
            </v-data-table>
          </div>
          <!-- </v-container> -->
        </div>
      </div>

      <v-dialog v-model="dialogNewProperty" width="500">
        <v-card>
          <v-card-title
            class="text-h5 pink white--text d-flex flex-row align-center justify-space-between"
          >
            <h5 class="title-md white--text mb-0">
              {{ $t("PropertyManagement.btn_add_property") }}
            </h5>
            <v-btn icon @click="closeDialogPropertyFunc()">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text
            v-if="loadingNewProperty"
            class="d-flex justify-center align-center mt-5"
          >
            <img
              :src="require('@/assets/img/thumb/loading.gif')"
              class="img-gif-loading"
            />
          </v-card-text>

          <v-card-text v-else>
            <label class="light my-3">
              {{ $t("SignupStep.select_property_type") }}
            </label>
            <div
              class="dropdown select"
              @click="getfocus('propertytype')"
              @mouseleave="mouseOver('propertytype')"
            >
              <a id="propertytype">
                <v-select
                  v-model="newproperty"
                  :items="itemsPropertyType"
                  item-text="name"
                  item-value="_id"
                  label
                  :placeholder="$t('SignupStep.select_one')"
                  :no-data-text="$t('SignupStep.nodata')"
                  color="pink"
                  solo
                  flat
                  dense
                  single-line
                  hide-details
                >
                  <template v-slot:item="data">
                    <v-list-item-content
                      @mouseenter="getfocus('propertytype')"
                      @mouseleave="mouseOver('propertytype')"
                    >
                      <v-list-item-title
                        v-html="data.item.name"
                        class="title-signup-sj"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.description"
                        class="label-signup-obj"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                  <template v-slot:no-data>
                    <v-btn @click="initProperty" text color="pink" style="width: 100%">{{
                      $t("SignupStep.btn_refresh")
                    }}</v-btn>
                  </template>
                </v-select>
              </a>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="white--text"
              color="pink"
              dark
              :disabled="!newproperty || loadingNewProperty"
              @click="addNewProperty()"
              >{{ $t("SignupStep.btn_next") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import i18n from "@/plugins/i18n";
import swal from "sweetalert";
import { EventBus } from "@/EventBus";

export default {
  name: "SigninPropertyManagementScreen",
  metaInfo: {
    title: "Property Management",
    titleTemplate: "%s | Aigent",
  },
  data: () => ({
    userToken: "",
    propertyID: "",
    userEmail: "",
    itemsProperty: [],
    headers: [],
    dialogNewProperty: false,
    itemsPropertyType: [],
    newproperty: "",
    value_element: "",
    newPropertyId: "",
    loadingNewProperty: false,
    userData: null,
    loadingUserData: false,
    allPropertyIncomplete: true,
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    self.initAll();
    // console.log(window.location.origin)
  },
  mounted() {
    EventBus.$on("refreshRightPhoto", this.initAll);
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-pink-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.add("border-pink-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.remove("border-pink-focus");
        this.value_element = "";
      }
    },
    initAll() {
      const self = this;

      self.headers = [
        {
          text: self.$t("PropertyManagement.header_id"),
          align: "start",
          sortable: false,
          value: "property_number",
        },
        {
          text: self.$t("PropertyManagement.header_name"),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: self.$t("PropertyManagement.header_location"),
          align: "start",
          sortable: false,
          value: "location",
        },
        // {
        //   text: self.$t("PropertyManagement.header_status"),
        //   align: "start",
        //   sortable: false,
        //   value: "status",
        // },
        { text: "", value: "actions", sortable: false },
      ];

      self.initUserData();
      self.initProperty();
    },
    async initUserData() {
      const self = this;
      var temp = [];
      self.loadingUserData = true;
      self.allPropertyIncomplete = true;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + "/my_user", {
          headers: {
            Authorization:
              self?.userToken ||
              localStorage?.getItem("Token") ||
              self?.$store?.state?.resSignUpData?.access_token,
            "X-Language-Code":
              localStorage?.getItem("language") || self?.$store?.state?.language,
          },
        });
        if (res.status == 200) {
          self.userData = res?.data?.result || null;
          // console.log(res);
          self.userEmail = res?.data?.result?.email || "";
          if (res?.data?.result?.property?.length) {
            res?.data?.result?.property?.map((ele) => {
              ele?.properties_detail?.map((el, i) => {
                if (el.property_progress == 100) self.allPropertyIncomplete = false;
                temp.push({
                  ...el,
                  img: el?.cover_picture
                    ? `${process.env.VUE_APP_PIC_API}/${el?.cover_picture}`
                    : null,
                  row_property: i,
                });
              });
            });
          }
        }
      } catch (error) {
        self.loadingUserData = false;
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        self.loadingUserData = false;
        EventBus.$emit("endloading");
        // console.log("temp: ", temp);
        self.itemsProperty = temp;
      }
    },
    checkRoleUserFunc() {
      const self = this;
      return self.userData?.role || "provider";
    },
    addNewPropertyNewTab() {
      const self = this;
      // console.log(self.newproperty);
      window.open(
        `${window.location.origin || process.env.VUE_APP_WEB}/signup/propertytype/${
          self.userToken
        }`
      );
    },
    async addNewProperty() {
      const self = this;
      // console.log(self.newproperty);
      // window.open(
      //   `${window.location.origin || process.env.VUE_APP_WEB}/signup/propertytype/${self.userToken}`
      // );
      self.loadingNewProperty = true;

      const obj = {
        property_type: self.newproperty,
      };
      var tempId = "";

      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.post(
          process.env.VUE_APP_API + "/property/next",
          obj,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          EventBus.$emit("endloading");
          tempId = res?.data?.result?._id;
          self.newPropertyId = tempId;
          self.sendVerify(self.userToken, self.newPropertyId);
        }
      } catch (error) {
        self.loadingNewProperty = false;
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    async sendVerify(accessToken, tempId) {
      const self = this;
      const numProperty = self?.itemsProperty?.length + 1 || 1;
      const obj = {
        email: self?.userEmail,
        link: `${
          window.location.origin || process.env.VUE_APP_WEB
        }/signup/basic/${accessToken}/${tempId}`,
        subject: `Information Property ${numProperty}`,
        button: "ADD PROPERTY",
      };
      self.loadingNewProperty = true;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.post(
          process.env.VUE_APP_API + "/property/sent_mail_exit",
          obj,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res?.status == 200) {
          setTimeout(() => {
            var itemLink = {
              link: `signup/basic/${self.userToken}/${tempId}`,
              name: "SignupBasicInformationScreen",
              params: { userToken: self.userToken, propertyId: tempId },
            };
            EventBus.$emit("changePathname", itemLink);
            self.loadingNewProperty = false;
            EventBus.$emit("endloading");
          }, 2000);
        } else {
          self.loadingNewProperty = false;
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            res?.data?.message || self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        self.loadingNewProperty = false;
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    async editProperty(item) {
      const self = this;
      EventBus.$emit("loadingtillend");
      var temp = null;
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/property?_id=${item?._id}`,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          if (res?.data?.result?.length) temp = res?.data?.result[0] || null;
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.propertyData = temp;
        self.checkGotoLink();
      }
    },
    checkGotoLink() {
      const self = this;
      EventBus.$emit("loadingtillend");
      var itemLink = null;
      // console.log(self?.propertyData);
      if (
        !self?.propertyData?.step_signup?.step1 ||
        !self?.propertyData?.step_signup?.step2 ||
        !self?.propertyData?.step_signup?.step3 ||
        !self?.propertyData?.step_signup?.step4 ||
        !self?.propertyData?.step_signup?.step5 ||
        !self?.propertyData?.step_signup?.step6 ||
        !self?.propertyData?.step_signup?.step7
      ) {
        // itemLink = {
        //   link: "signup/basic",
        //   name: "SignupBasicInformationScreen",
        //   params: {
        //     userToken: self.userToken,
        //     propertyId: self?.propertyData?._id,
        //   },
        // };
        // EventBus.$emit("changePathname", itemLink);
        window.open(
          `${window.location.origin || process.env.VUE_APP_WEB}/signup/basic/${
            self.userToken
          }/${self.propertyData?._id}`
        );
        EventBus.$emit("endloading");
      } else if (
        self?.propertyData?.step_signup?.step1 &&
        self?.propertyData?.step_signup?.step2 &&
        self?.propertyData?.step_signup?.step3 &&
        self?.propertyData?.step_signup?.step4 &&
        self?.propertyData?.step_signup?.step5 &&
        self?.propertyData?.step_signup?.step6 &&
        self?.propertyData?.step_signup?.step7
      ) {
        if (self?.propertyData?.manager_detail?.success) {
          itemLink = {
            link: "provider/setting/generalinfo",
            name: "SigninGeneralInfoScreen",
            // link: "provider/dashboard",
            // name: "SigninDashboardScreen",
            params: {
              userToken: self.userToken,
              propertyId: self?.propertyData?._id,
            },
          };
          EventBus.$emit("changePathname", itemLink);
        } else if (self?.propertyData?.owner_detail?.success) {
          itemLink = {
            link: "managerdetail",
            name: "SignupManagerDetailScreen",
            params: {
              userToken: self.userToken,
              propertyId: self?.propertyData?._id,
            },
          };
          EventBus.$emit("changePathname", itemLink);
        } else if (self?.propertyData?.properties_detail?.success) {
          itemLink = {
            link: "ownerdetail",
            name: "SignupOwnerDetailScreen",
            params: {
              userToken: self.userToken,
              propertyId: self?.propertyData?._id,
            },
          };
          EventBus.$emit("changePathname", itemLink);
        } else {
          itemLink = {
            link: "propertydetail",
            name: "SignupPropertyDetailScreen",
            params: {
              userToken: self.userToken,
              propertyId: self?.propertyData?._id,
            },
          };
          EventBus.$emit("changePathname", itemLink);
        }
      } else {
        itemLink = {
          // link: "provider/dashboard",
          // name: "SigninDashboardScreen",
          link: "provider/setting/generalinfo",
          name: "SigninGeneralInfoScreen",
          params: {
            userToken: self.userToken,
            propertyId: self?.propertyData?._id,
          },
        };
        EventBus.$emit("changePathname", itemLink);
      }
    },
    delProperty(item) {
      const self = this;
      // console.log("item: ", item);
      swal(self.$t("Alert.qtneedtoremove"), {
        dangerMode: true,
        buttons: {
          cancel: self.$t("Alert.btn_no"),
          confirm: {
            text: self.$t("Alert.btn_yes"),
            value: "confirm",
          },
        },
      }).then(async (value) => {
        if (value === "confirm") {
          EventBus.$emit("loadingtillend");
          try {
            const res = await self.axios.delete(
              process.env.VUE_APP_API + `/property?property_id=${item._id}`,
              {
                headers: {
                  Authorization: self?.userToken,
                  "X-Language-Code":
                    localStorage?.getItem("language") || self?.$store?.state?.language,
                },
              }
            );
            if (res?.status == 200) {
            } else {
              swal(
                self.$t("Alert.warn_title"),
                res?.data?.message || "Please try again",
                self.$t("Alert.warn_label"),
                {
                  button: false,
                  timer: 3000,
                }
              );
            }
          } catch (error) {
            EventBus.$emit("endloading");
            console.log(error?.response?.data?.message || error);
            swal(
              self.$t("Alert.warn_title"),
              error?.response?.data?.message || "Please try again",
              self.$t("Alert.warn_label"),
              {
                button: false,
                timer: 3000,
              }
            );
          } finally {
            self.initAll();
          }
        }
      });
    },
    async initProperty() {
      const self = this;
      var temp = [];
      self.itemsPropertyType = [];

      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + "/setting/property_type",
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          res.data.result.map((el) => {
            if (el?.value && (el?.description || el?.desciption) && el?.status)
              temp.push({
                _id: el._id,
                name: el.value || "",
                value: el._id,
                description:
                  el?.description ||
                  el?.desciption ||
                  self.$t("SignupStep.no_property_type_description"),
              });
          });
          self.itemsPropertyType = temp;
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      }
    },
    closeDialogPropertyFunc() {
      const self = this;
      self.dialogNewProperty = false;
      self.loadingNewProperty = false;
      self.newproperty = "";
    },
  },
};
</script>

<style scoped>
@import "../../../styles/bootstrap/global.css";
@import "../../../styles/signin/setting.css";

.property-img {
  width: 5rem;
  height: 5rem;
  object-fit: cover;
  border-radius: 50%;
}

.flag-img {
  width: 1.3rem;
  height: 1.3rem;
  object-fit: contain;
}

.tag-property-status {
  padding: 0.5rem;
  border-radius: 4px;
  background-color: rgb(89, 49, 226);
  color: #fff;
}

/* .container {
  width: 100vw !important;
  height: 100vh !important;
} */
/* .v-application {
  background-color: rgb(244, 245, 250) !important;
} */
.page-slideout {
  background-color: rgb(244, 245, 250) !important;
}
/* .theme--light .v-application {
  background-color: rgb(244, 245, 250) !important;
} */
</style>

<style>
.v-application {
  background-color: rgb(244, 245, 250) !important;
}
</style>

<style lang="scss" scoped>
.border-pink-focus {
  // background-color: #fff;
  border-color: var(--pink) !important;
}
</style>
